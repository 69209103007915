;var shopStatic = window.shopStatic || {};

shopStatic.product = function () {

};

shopStatic.product.prototype.getProductUserInfo = function () {
    var pid = $('#pid').val();
    if (pid) {
        const urlParams = $u.getUrlParams();
        const urlParamCurrency = 'currency' in urlParams ? urlParams['currency'] : '';
        let url = '/api/product/info/' + pid;
        if (urlParamCurrency !== '') url += `?currency=${urlParamCurrency}`;

        return $u.http.get(url)
            .then(function (response) {
                let result = response.data;
                if (result.code === 200 && result.data) {
                    let product = result.data.product;
                    const $productBody = $('.js-product-' + product.pid);
                    $productBody.find('.js-prod-price .price-symbol').text(product.currency_symbol);
                    $productBody.find('.js-prod-price .price-value').text(product.price);

                    if(product.list_price){
                        $productBody.find('.js-list-price .price-symbol').text(product.currency_symbol);
                        $productBody.find('.js-list-price .price-value').text(product.list_price);
                        $productBody.find('.price .discount').removeClass('hide').text(`-${product.discount}`);
                    }

                    //like
                    if (product.isLiked) {
                        $productBody.find('.js-prod-like').addClass('liked').find('.text').text('liked');
                    } else {
                        $productBody.find('.js-prod-like').removeClass('liked').find('.text').text('like');
                    }

                    $productBody.find('.js-prod-like .count').text(`(${product.likes})`);

                    Object.assign($d.product.detail, product);       // store product info.
                    if ($('.stock-all-spec-tips').length > 0) {      // 全品现货商品使用现货eta
                        shopJS.prod.setETAInfo($d.product.detail.eta, true)   //更新eta
                    } else {
                        shopJS.prod.setETAInfo($d.product.detail.eta, $('.tab-item.in-stock.cur').length > 0)   //更新eta
                    }
                    shopJS.prod.initInStockData();                  //处理现货商品的颜色尺码信息。
                }
            }).catch(function (error) {
                console.log(error);
            });
    }
};

shopStatic.product.prototype.getFabricProduct = function () {
    var $orderSwatch = $('.js-order-swatch');
    if ($orderSwatch.length) {
        return $u.http.get('/api/product/info/' + $orderSwatch.find('.js-fabric-add-to-cart').data('pid'))
            .then(function (response) {
                var result = response.data;
                if (result.code == 200 && result.data) {
                    var fabricProduct = result.data.product;
                    $('.js-fabric-price').text(fabricProduct.c_price);
                }
            }).catch(function (error) {
                console.log(error);
            });
    }
};

// history
shopStatic.product.prototype.getMatchProduct = function () {
    const $matchList = $('.match-list-box');

    if ($matchList.length) {
        const pid = $matchList.data('pid');

        const urlParams = $u.getUrlParams();
        const urlParamCurrency = 'currency' in urlParams ? urlParams['currency'] : '';
        let url = `/api/product/match-recommend/${pid}`;
        if (urlParamCurrency !== '') url += `?currency=${urlParamCurrency}`;
        return $u.http.get(url)
            .then(function (response) {
                let result = response.data;
                const $list = $matchList.find('.list');
                // console.log(result);
                if (result.code === 200 && result.data) {
                    if (result.data.length > 0) {
                        let template = '';
                        $.each(result.data, function (index, product) {
                            template += `
                            <div class="item">
                                <div class="img-wrap" href="${product.url}" target="_self">
                                    <img src="${product.image + ($d.config.isSupportWebp ? '?format=webp' : '')}"
                                         decoding="async" loading="lazy" alt="${product.name}" />
                                </div>
                                <div class="mp-price notranslate">
                                    <div class="price-symbol">${product.currency_symbol}</div>
                                    <div class="price-value">${product.price}</div>
                                </div>  
                                <div class="mp-add-to-cart" data-pid="${product.pid}" 
                                data-allow-add-bag="${product.justAddCart}" 
                                data-select-color="${product.color}" >
                                     <div class="text">Add</div>
                                     <div class="icon-s"></div>
                                 </div>
                            </div>`;
                        });
                        template += '<div class="item empty"></div>';
                        $list.html($(template));
                        $matchList.removeClass('hide');
                        shopJS.prod.bindBuyMatchProd();
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }
};


